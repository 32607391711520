body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body { 
  overflow-x: hidden; 
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer-events-none {
  pointer-events: none;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-12px {
  font-size: 12px;
}

.h-500px {
  height: 500px !important;
}

.page-bg {
  font-size: 8vw;
  font-weight: 900;
}

.h-40vh {
  height: 40vh !important;
  height: calc(var(--vh, 1vh) * 40) !important;
}

.h-100vh {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important
}

.carousel-item > .view {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.line-height-1 {
  line-height: 1;
}

.font-size-1-375rem {
  font-size: 1.375rem !important;
}

.font-size-1-25rem {
  font-size: 1.25rem !important;
}

.font-size-1-1rem {
  font-size: 1.1rem !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.carousel .carousel-indicators li.active {
  width: .725rem !important;
  height: .725rem !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: white !important;
  background-color: unset !important
}

.gradient-bg-primary { 
background: linear-gradient(80deg,#161a1b,#6b74b3) !important;
}

.gradient-bg-secondary {
  background: linear-gradient(80deg,#69797e,#9aa0c7) !important;
}

