.accordion {
    transition: all 0.5s ease-in-out;
  }
  
.active-accordion {
    transform: rotate(180deg);
  }

.resource-link {
    text-decoration: underline;
    font-weight: 300;
    font-size: 1.1rem;
    transition: all 0.5s ease-in-out;
  }
  
.resource-link:hover {
    margin-left: 10px;
    font-weight: bold !important;
  }