.scroll-top {
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 99999;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  
.scroll-top-visible {
    opacity: 1;
  }

.scroll-top-btn {
  border-radius: 50% !important;
  opacity: 0.7;
  width: 30px;
  height: 30px;
  transition: all 0.25s ease-in-out !important;
}

.scroll-top-btn:hover {
  opacity: 1;
  width: 35px;
  height: 35px;
}